import React, { useRef } from "react";
import ButtonPrimary from "../components/Buttons";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";

import emailjs from "@emailjs/browser";
import styled from "styled-components";
import telBig from "../img/tel-big.jpg";

const PlaceOrder = () => {
  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_manclwv",
        "template_7knhy5u",
        form.current,
        "eNJB4AmIg4Vo0XOes"
      )
      .then(
        (result) => {
          e.target.reset();
          navigate("/thank-you");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  ReactGA.event({
    category: "User",
    action: "Requested a quote",
  });

  return (
    <StyledPlaceOrder className="place-order">
      <div className="content space-big">
        <div className="Hero space-big">
          <div className="hero-wrapper container">
            <h1 className="split">
              Fill in your project details and get a quick response
            </h1>
          </div>

          <svg className="svg-heroes">
            <clipPath id="clip-path-heroes" clipPathUnits="objectBoundingBox">
              <path d="M1,0 V1 a4,10,0,0,0,-1,0 V0"></path>
            </clipPath>
          </svg>
        </div>

        <div className="form-title space-bigger-mid-top container">
          <h3 className="subheading">Get a quote in minutes</h3>
        </div>

        <div className="form-holder">
          <div className="form-wrapper container space-big">
            <form className="form" ref={form} onSubmit={sendEmail}>
              <div className="form-group">
                <div className="form-field">
                  <input
                    className="form-input"
                    type="text"
                    id="name"
                    name="user_name"
                    placeholder="Your Name"
                    required
                  />
                  <label className="form-label" htmlFor="name">
                    Full Name
                  </label>
                </div>
                <div className="form-field">
                  <input
                    className="form-input"
                    type="email"
                    id="email"
                    placeholder="Email address"
                    name="user_email"
                    required
                  />
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="form-field">
                  <input
                    className="form-input"
                    type="text"
                    id="service"
                    name="service"
                    placeholder="Voiceover"
                    required
                  />
                  <label className="form-label" htmlFor="service">
                    Service Required
                  </label>
                </div>
                <div className="form-field">
                  <input
                    className="form-input"
                    type="number"
                    id="budget"
                    name="budget"
                    placeholder="Budget ($250)"
                    required
                  />
                  <label className="form-label" htmlFor="budget">
                    Budget
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="form-field">
                  <input
                    className="form-input"
                    type="text"
                    name="source_language"
                    id="source-language"
                    placeholder="Swahili"
                  />
                  <label className="form-label" htmlFor="source-language">
                    Source Language
                  </label>
                </div>
                <div className="form-field">
                  <input
                    className="form-input"
                    type="text"
                    id="target-language"
                    name="target_language"
                    placeholder="English"
                  />
                  <label className="form-label" htmlFor="budget">
                    Budget
                  </label>
                </div>
              </div>
              <div className="textarea-holder">
                <textarea name="message" id="brief" className="textarea">
                  Description...
                </textarea>
                <label className="textarea-label" htmlFor="brief">
                  Project brief
                </label>
              </div>

              {/* <div className="file-upload form-group">
            <label htmlFor="upload">
              {" "}
              Select File
              <input type="file" name="upload" id="upload" />
            </label>
          </div> */}

              <div className="submit">
                <ButtonPrimary type="submit" value="Send">
                  Submit
                </ButtonPrimary>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </StyledPlaceOrder>
  );
};

export default PlaceOrder;

const StyledPlaceOrder = styled.div`
  overflow: hidden;
  .content {
    padding: 0;
  }
  .hero-wrapper {
  }
  h3 {
    text-align: center;
  }

  .form-wrapper {
    margin-top: 4.7rem;
    width: 70%;
    background: rgba(#fff, 0.9);
    box-shadow: 0 0.6rem 1rem 0.1rem rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0.6rem 1rem 0.1rem rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0.6rem 1rem 0.1rem rgba(0, 0, 0, 0.15);
    .form-group {
      display: flex;
      justify-content: space-between;
      .form-field {
        margin: auto;
        flex: 1;
        margin: 1.6rem 2.4rem;
        input {
          font-family: inherit;
          font-size: 1.6rem;
          padding: 1.5rem 4.7rem;
          border-radius: 0.2rem;
          border-radius: 0.3rem;
          border: 0.1rem solid var(--afrovo-blue-dark);
          width: 100%;
          display: block;
          transition: all 0.3s;
        }
        .form-label {
          font-size: 1.2rem;
          font-weight: 700;
          margin-left: 4.7rem;
          margin-top: 0.8rem;
          display: block;
          transition: all 0.4s;
          color: var(--afrovo-blue);
        }
        .form-input:focus {
          outline: none;
          border-bottom: 0.3rem solid var(--afrovo-orange);
        }
        .form-input:placeholder-shown + .form-label {
          opacity: 0;
          visibility: hidden;
          transform: translateY(-4.7rem);
        }
      }
    }
    .textarea-holder {
      margin: 1.6rem 2.4rem;
      textarea {
        width: 100%;
        font-size: 1.6rem;
        padding: 3.2rem 4.7rem;
        border: 0.1rem solid var(--afrovo-blue-dark);
        border-radius: 0.3rem;
        width: 100%;
        display: block;
        transition: all 0.3s;
        &:focus {
          outline: none;
          // border: none;
          border-bottom: 0.3rem solid var(--afrovo-orange);
        }
      }
      .textarea-label {
        opacity: 0;
        visibility: hidden;
        font-size: 1.2rem;
        font-weight: 700;
        margin-left: 4.7rem;
        margin-top: 0.8rem;
        display: block;
        transition: all 0.4s;
      }
      textarea:focus + label {
        transform: translateY(0.8rem);
        opacity: 1;
        visibility: visible;
      }
    }
    .file-upload {
      margin: 3.2rem 2.4rem;
      label {
        width: 100%;
        background: #b1afaf;
        font-size: 1.6rem;
        padding: 1.5rem 4.7rem;
        border-radius: 0.3rem;
      }
      input {
        display: none;
      }
    }
    .submit {
      margin: 4.7rem 2.4rem 0 0;
      color: red;
      text-align: right;
    }
  }
  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    .hero-wrapper {
      h1 {
        margin-bottom: 4.7rem;
      }
    }
    h3 {
      text-align: left;
      width: 92%;
      margin: auto;
    }
    .form-holder {
      .form-wrapper {
        width: 92%;
        margin: auto;
        background: rgba(#fff, 0.9);
        box-shadow: none;
        form {
          .form-group {
            flex-direction: column;
            .form-field {
            }
          }
          .submit {
            text-align: left;
            width: 92%;
            margin: auto;
          }
        }
      }
    }
  }
`;
