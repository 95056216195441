import styled from "styled-components";
import shield from "../img/shield.png";
import quality from "../img/quality.png";
import rocket from "../img/rocket.png";
import { ButtonPrimaryBlue } from "./Buttons";

const AfrovoAwards = () => {
  return (
    <StyledAwards className="awards space-big-bottom">
      <div className="awards-wrapper container">
        <h3 className="subheading space-bigger-mid-bottom">So, why Afrovo?</h3>
        <div className="awards-cards">
          <div className="awards-card space-bigger-mid">
            <img src={shield} alt="" />
            <h4 className="space-bigger-small">5-Star rating</h4>
            <p>
              Our high rating on Trustpilot and Clutch, with an average rating
              of 4.9, is a testament to the satisfaction of our clients and
              contractors. When you work with us, you can be sure that you're
              getting a top-notch service that is praised by all parties
              involved."
            </p>
          </div>
          <div className="awards-card space-bigger-mid">
            <img src={quality} alt="" />
            <h4 className="space-bigger-small">Quality is our #1 priority</h4>
            <p>
              Our focus is on providing fast delivery of premium recordings at
              an affordable price. Whether you prefer to record on-site or
              remotely, we offer flexible options to suit your needs and ensure
              the best outcome for your project
            </p>
          </div>
          <div className="awards-card space-bigger-mid">
            <img src={rocket} alt="rocket" />
            <h4 className="space-bigger-small">Limitless possibilities</h4>
            <p>
              Expand your reach and connect with global audiences like never
              before. Our platform offers the ability to produce content in any
              language or accent, catering to all types of industries - from
              advertising, education, entertainment to business.{" "}
            </p>
          </div>
        </div>
      </div>
    </StyledAwards>
  );
};

export default AfrovoAwards;

const StyledAwards = styled.div`
  .awards-wrapper {
    text-align: center;
    .awards-cards {
      display: flex;
      .awards-card {
        flex: 1;
        margin: 0 1.6rem;
        box-shadow: 0 0.6rem 1rem 0.1rem rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 0 0.6rem 1rem 0.1rem rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0 0.6rem 1rem 0.1rem rgba(0, 0, 0, 0.15);
        p {
          width: 70%;
          margin: auto;
        }
      }
    }
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    .awards-wrapper {
      margin: auto;
      h3 {
        width: 92%;
        margin: auto;
        margin-top: 6.4rem;
        margin-bottom: 0;
      }
      .awards-cards {
        flex-direction: column;
        .awards-card {
          text-align: left;
          box-shadow: none;
          padding: 2.4rem 0;
          margin-bottom: 1.6rem;
          &:last-child {
            margin-bottom: 1.2rem;
          }
          p {
            width: auto;
          }
        }
      }
    }
  }
`;
