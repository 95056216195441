import React from "react";
import ButtonPrimary, {
  ButtonPrimarySecondary,
  ButtonPrimaryBlue,
  ButtonPrimarySecondaryWhite,
} from "../components/Buttons";

import HeroData from "./HeroData";

const Hero = (props) => {
  // console.log(props);
  return (
    <div className="Hero space-big">
      <div className="hero-wrapper container">
        <h1 className="split space-bigger-top">{HeroData.howItWorksHeading}</h1>
        <p className="space-mid">{HeroData.howItWorksParagraph}</p>
        <div className="space-big">
          <a href="/place-order">
            <ButtonPrimary>Get Started</ButtonPrimary>
          </a>
        </div>
      </div>

      <svg className="svg-heroes">
        <clipPath id="clip-path-heroes" clipPathUnits="objectBoundingBox">
          <path d="M1,0 V1 a4,10,0,0,0,-1,0 V0"></path>
        </clipPath>
      </svg>
    </div>
  );
};

export default Hero;
