import React from "react";

// Import Images
import heroModel from "../img/model-hero.png";
import heroMobile from "../img/hero-mobile.png";

//IMPORT COMPONENT
import ButtonPrimary, {
  ButtonPrimarySecondary,
  ButtonPrimaryBlue,
  ButtonPrimarySecondaryWhite,
} from "./Buttons";

const HomeHero = () => {
  return (
    <div>
      <div className="home-hero">
        <div id="hero-section" className="container">
          <div className="hero-content space-big">
            <h1>African accents done right</h1>
            <p className="space-mid">
              We provide comprehensive solutions for your film, ad and eLearning
              projects. Trust us to handle the translation and voice-over
              services, so you can focus on creating impactful content.
            </p>
            <div className="hero-cta-wrapper">
              {/* <ButtonComponent primary={true}>Place Order</ButtonComponent>
                  <ButtonComponent>Get a Quote</ButtonComponent> */}

              <a className="hero-place-order" href="/place-order">
                <ButtonPrimary>Place Order</ButtonPrimary>
              </a>
              <a className="hero-get-quote" href="/place-order">
                <ButtonPrimarySecondaryWhite>
                  Qet a Quote
                </ButtonPrimarySecondaryWhite>
              </a>
            </div>
          </div>
          <div className="hero-image-wrapper">
            {/* <picture>
                <source media="(min-width:600px)" srcset={heroModel}
                <img src={mobileHero} />
                

            </picture> */}
            <picture>
              <source media="(min-width: 600px)" srcset={heroModel} />
              <img src={heroMobile} />
            </picture>
            {/* <img
              src={heroModel}
              alt="African accent voiceovers hero model image"
            /> */}
          </div>
        </div>

        <svg className="svg">
          <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
            <path d="M0.88,1 c-0.011,0,-0.022,0,-0.033,0 c-0.001,-0.001,-0.002,-0.001,-0.004,-0.001 c-0.012,0,-0.025,-0.001,-0.037,-0.002 c-0.015,-0.002,-0.03,-0.006,-0.045,-0.01 c-0.041,-0.011,-0.081,-0.028,-0.121,-0.049 c-0.018,-0.009,-0.037,-0.02,-0.055,-0.03 c-0.026,-0.015,-0.053,-0.026,-0.081,-0.03 c-0.03,-0.005,-0.06,0,-0.09,0.01 c-0.03,0.01,-0.06,0.024,-0.09,0.036 c-0.038,0.015,-0.077,0.028,-0.115,0.037 c-0.018,0.004,-0.037,0.007,-0.056,0.01 c-0.016,0.002,-0.032,0.003,-0.048,0.005 c-0.033,0.003,-0.066,0.002,-0.099,0.002 c-0.002,0,-0.004,0,-0.006,-0.003 C0,0.651,0,0.328,0,0.005 C0,0,0.001,0,0.003,0 c0.331,0,0.662,0,0.993,0 c0.003,0,0.003,0,0.003,0.005 c0,0.323,0,0.646,0,0.969 c-0.001,0.003,-0.003,0.004,-0.006,0.005 c-0.019,0.007,-0.039,0.013,-0.058,0.017 c-0.015,0.003,-0.03,0.003,-0.044,0.003 C0.888,1,0.884,0.999,0.88,1"></path>
          </clipPath>
        </svg>
      </div>
    </div>
  );
};

export default HomeHero;
