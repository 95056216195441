const HeroData = {
  howItWorksHeading: "Bring your stories to life with authentic accents",
  howItWorksParagraph: `Unlock the power of authentic accents for your project. Everything teams
need to produce native content or localization in any language or accent is
just a click away.`,
  pricingHeading: `Afrovo Customer 
Pricing Guide`,
  pricingParagraph: `We take pride in our ability to deliver cost-effective and superior quality audio and visual work. We are dedicated to ensuring that your project, whether big or small, is completed to perfection. Don't hesitate, contact us today for a free and detailed quote.`,
  contactHeading: `Love to hear from you,
Get in touch `,
};

export default HeroData;
