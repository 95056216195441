import { Link } from "react-router-dom";
import styled from "styled-components";

const Footer = () => {
  return (
    <StyledFooter className="footer">
      <div className="footer-wrapper container">
        <div className="footer-menu">
          <h5 className="space-mid">
            <Link to="/">Afrovo</Link>
          </h5>

          <ul>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/about">Why Choose Us</Link>
            </li>
            <li>
              <Link to="/pricing">Pricing</Link>
            </li>
            <li>
              <Link to="#">Testimonial</Link>
            </li>
          </ul>
        </div>
        <div className="footer-menu">
          <h5 className="space-mid">
            <Link to="#">Resources</Link>
          </h5>

          <ul>
            <li>
              <Link to="/">Blog</Link>{" "}
            </li>
            <li>
              <Link to="#">Privacy Policy</Link>
            </li>
            <li>
              <Link to="#">Terms and conditions</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="footer-menu">
          <h5 className="space-mid">
            <Link to="/about">Product</Link>
          </h5>
          <ul>
            <li>
              <Link to="/about"> Product Management</Link>
            </li>
            <li>
              <Link to="#"> Time Tracker</Link>
            </li>
            <li>
              <Link to="/contact"> Support</Link>
            </li>
            <li>
              <Link to="/contact"> Talk to sales</Link>
            </li>
          </ul>
        </div>
        <div className="footer-menu reverse">
          <h5 className="space-mid">
            <img src="/afrovo-logo-x1.png" alt="Afrovo Logo" />
          </h5>
          <div className="newsletter">
            <p>Subscribe to our newsletter</p>
            <form action="submit">
              <input type="email" required />
              <button>Subscribe</button>
            </form>
          </div>
        </div>
      </div>

      <svg className="footer-svg">
        <clipPath id="footer-path" clipPathUnits="objectBoundingBox">
          <path d="M1,0.322 V1 H0 V0.304 C0.357,0.363,0.657,0.394,1,0.322"></path>
        </clipPath>
      </svg>
    </StyledFooter>
  );
};

export default Footer;

const StyledFooter = styled.footer`
  background: var(--afrovo-blue-dark);
  color: var(--afrovo-white);
  -webkit-clip-path: url(#footer-path);
  clip-path: url(#footer-path);
  margin-top: -10%;
  z-index: 0;

  .footer-wrapper {
    display: flex;
    padding-top: 20%;

    .footer-menu {
      flex: 1;
      h5 {
        font-size: 1.8rem;
        font-weight: 500;
        a {
          text-decoration: none;
          color: #fff;
        }
      }
      ul {
        list-style-type: none;
        font-weight: 300;
        font-size: 1.2rem;
        li a {
          text-decoration: none;
          color: #fff;
          &:hover {
            color: var(--afrovo-orange);
          }
        }
      }

      form {
        margin-top: 1.6rem;
        display: flex;
        input {
          border-radius: 0.8rem;
          padding: 1.2rem;
          background: #2b2e3c;
          border: none;
          z-index: 1;
        }

        button {
          border-radius: 0.8rem;
          padding: 1.2rem;
          margin-right: -10%;
          border: none;
          z-index: 100;
        }
      }
    }
  }

  @media (max-width: 575.98px) {
    clip-path: none;
    margin-top: 4.7rem;
    .footer-wrapper {
      flex-direction: column;
      text-align: center;
      padding: 4.7rem 0;
      .newsletter:not(img) {
        display: none;
      }
    }
    .footer-svg {
      display: none;
      height: 0;
    }
  }
`;
