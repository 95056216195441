// import components
import AfrovoAwards from "../components/AfrovoAwards";
import { ButtonPrimaryBlue } from "../components/Buttons";
import Footer from "../components/Footer";
import GotQuestions from "../components/GotQuestions";
import Hero from "../components/Hero";
import HiwInfographic from "../components/HiwInfographic";
import Testimonials from "../components/Testimonials";
// Animations
import { motion } from "framer-motion";

const HowItWorks = ({}) => {
  return (
    <motion.div>
      <div className="content">
        <Hero />
        <HiwInfographic />
        <div className="awards">
          <div className="section-wrapper space-big-bottom">
            <AfrovoAwards />
            <a href="/place-order">
              <ButtonPrimaryBlue> Tell us about your project</ButtonPrimaryBlue>
            </a>
          </div>
        </div>
        <Testimonials />
        <GotQuestions />
      </div>
      <Footer />
    </motion.div>
  );
};

export default HowItWorks;
