import React from "react";

//Import Images
import voiceoverIcon from "../img/voiceover-icon.png";
import dubbingIcon from "../img/Dubbing-icon.png";
import localizationIcon from "../img/localization-icon.png";
import translationIcon from "../img/translation-icon.png";

const ServiceTypes = () => {
  return (
    <div className="service-types ">
      <h3 className="subheading space-bigger-mid-bottom container top">
        Your source for professional African accent voiceovers
      </h3>
      <div className="service-type-wrapper container">
        <div className="service-type-1 service-type-item">
          <img src={voiceoverIcon} alt="voiceover" />
          <h4 className="blue space-mid">Voiceover</h4>
          <p className="justified">
            With over 10,000 voice artists located throughout the continent of
            Africa, Afrovo is your one-stop source for finding the perfect
            African accent for your voiceover project
          </p>
        </div>
        <div className="service-type-2 service-type-item">
          <img src={dubbingIcon} alt="dubbing" />
          <h4 className="blue space-mid">Dubbing</h4>
          <p className="justified">
            Explore our dubbing services for film, video games, and online
            video. Our voiceover artists provide high-quality dubbing in all
            languages. Listen to demos, and choose the best artist for your
            project. We help you achieve authentic African accents.
          </p>
        </div>
        <div className="service-type-3 service-type-item">
          <img src={localizationIcon} alt="localization" />
          <h4 className="blue space-mid">Localization</h4>
          <p className="justified">
            Adapt your videos, apps, and more to match regional audience needs
            and preferences. Tailor your content to local culture and language
            to increase engagement and satisfaction, especially when expanding
            into new markets or working with international clients.
          </p>
        </div>
        <div className="service-type-4 service-type-item">
          <img src={translationIcon} alt="translation" />
          <h4 className="blue space-mid">Translation</h4>
          <p className="justified">
            Afrovo takes pride in providing professional translation services
            that help you accurately convey your message across languages. Our
            team of skilled translators are fluent in a range of languages, and
            are able to accurately translate your content into the language of
            your choice.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServiceTypes;
