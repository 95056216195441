import styled from "styled-components";
import AfrovoAwards from "../components/AfrovoAwards";
import ButtonPrimary, {
  ButtonPrimaryBlue,
  ButtonPrimarySecondaryWhite,
} from "../components/Buttons";
import Footer from "../components/Footer";
import GotQuestions from "../components/GotQuestions";
import HeroData from "../components/HeroData";
import Testimonials from "../components/Testimonials";

const Pricing = () => {
  return (
    <StyledPricing className="pricing">
      <div className="content">
        <div className="Hero space-big">
          <div className="hero-wrapper container">
            <h1>{HeroData.pricingHeading}</h1>
            <p className="space-mid">{HeroData.pricingParagraph}</p>
            <div className="space-bigger-mid-bottom">
              <a href="/contact">
                <ButtonPrimarySecondaryWhite>
                  Talk to sales
                </ButtonPrimarySecondaryWhite>
              </a>
            </div>
          </div>
        </div>

        <div className="pricing-cards space-big container">
          <div className="pricing-card">
            <h4>Casting</h4>
            <p className="space-bigger-small">
              Trust our team to find the perfect voice actors for your project,
              big or small.
            </p>
            <h5>
              From: <span>Free</span>{" "}
            </h5>
          </div>
          <div className="pricing-card">
            <h4>Narration</h4>
            <p className="space-bigger-small">
              Experience professional audio recording in a verified home studio.
              Professional audio engineering and editing as separate files.
            </p>
            <h5>
              From: <span>$0.02 per word</span>
            </h5>
          </div>
          <div className="pricing-card">
            <h4>Corporate</h4>
            <p className="space-bigger-small">
              Create an impactful voiceover for your website explainer or
              corporate film.
            </p>
            <h5>
              From: <span>$350</span>
            </h5>
          </div>
          <div className="pricing-card">
            <h4>Game Characters</h4>
            <p className="space-bigger-small">
              Cast and record voice actors for different roles in an indie or
              mobile video game or a fan animation project
            </p>
            <h5>
              From: <span>$350</span>
            </h5>
          </div>
        </div>

        <div className="align-center ">
          <ButtonPrimaryBlue>
            <i className="fa-solid fa-download iframe-left"></i>Download Rate
            Sheet
          </ButtonPrimaryBlue>
        </div>

        <div className="section-wrapper space-big">
          <div className="space-big">
            <AfrovoAwards />
            <div className="align-center ">
              <a href="/place-order">
                <ButtonPrimary> Tell us about your project</ButtonPrimary>
              </a>
            </div>
          </div>
        </div>

        <Testimonials />
        <GotQuestions />

        <svg className="svg-heroes">
          <clipPath id="clip-path-heroes" clipPathUnits="objectBoundingBox">
            <path d="M1,0 V1 a4,10,0,0,0,-1,0 V0"></path>
          </clipPath>
        </svg>
      </div>
      <Footer />
    </StyledPricing>
  );
};

export default Pricing;

const StyledPricing = styled.div`
  .pricing-cards {
    display: flex;
    .pricing-card {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      background: rgba(33, 158, 188, 0.1);
      box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
      border-radius: 0.8rem;
      margin: 0 1.6rem;
      padding: 3.2rem;
      h5 {
        background: var(--afrovo-white);
        padding: 0.8rem;
        border-radius: 0.4rem;
      }
    }
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    .pricing-cards {
      flex-direction: column;
      .pricing-card {
        box-shadow: none;
        margin-bottom: 4.7rem;
        &:last-child {
        }
      }
    }
  }
`;
