import { useRef } from "react";
import styled from "styled-components";
import Toggle from "./Toggle";
import { AnimateSharedLayout, motion } from "framer-motion";

const Faq = () => {
  const answerRef = useRef();

  return (
    <StyledFaq className="faq container space-big">
      <h3 className="subheading space-bigger-mid-bottom">
        Frequently Asked Questions
      </h3>

      <AnimateSharedLayout>
        <Toggle title="What is a voice-over?">
          <div className="question">
            <div className="answer">
              <p>
                Voice-over is the art of adding a vocal audio track to various
                forms of media such as radio, television, film, video games,
                commercials, and more. It's a crucial aspect of many projects,
                from an audiobook narration to a high-end video production. The
                versatility of voice-over makes it an essential tool for
                businesses seeking to enhance their brand awareness, advertising
                campaigns, and content delivery.
              </p>

              <p>
                In addition to its use in traditional media, voice-over can also
                be found in more subtle forms, such as phone messages,
                educational videos, and customer guides. A professionally
                recorded voice-over brings a level of artistry and emotion to a
                production, making it more engaging and memorable for the
                audience.
              </p>
            </div>
          </div>
        </Toggle>

        <Toggle title="  What makes a good voiceover?">
          <div className="question">
            <div className="answer">
              <p>
                A good voiceover is one that effectively communicates the
                intended message while engaging the audience and enhancing the
                overall production. The following are some key qualities of a
                good voiceover:
              </p>
              <p></p>
              <ul>
                <li>
                  <strong>Clarity:</strong> A clear and articulate delivery that
                  is easy to understand.
                </li>
                <li>
                  <strong>Emotion:</strong> The ability to evoke the right
                  emotions to help tell the story and engage the audience.
                </li>
                <li>
                  <strong>Timing:</strong> Good timing in regards to pace and
                  emphasis to keep the audience interested.
                </li>
                <li>
                  <strong>Character:</strong> A unique and memorable voice that
                  adds character and personality to the production.
                </li>
                <li>
                  <strong>Versatility:</strong> The ability to adapt to
                  different styles, genres, and tones as required by the
                  project.
                </li>
                <li>
                  <strong>Consistency:</strong> Maintaining consistent vocal
                  quality and style throughout the production.
                </li>
                <li>
                  <strong>Professionalism:</strong> A professional approach to
                  recording, including accurate pronunciation, proper
                  inflection, and clear diction.
                </li>
              </ul>
              <p>
                Ultimately, a good voiceover is one that delivers the desired
                impact and enhances the overall production in a way that
                connects with the audience.
              </p>
            </div>
          </div>
        </Toggle>

        <Toggle title="What is the purpose of voiceover and do I need one for my project?">
          <div className="question">
            <div className="answer">
              <p>
                The purpose of voiceover is to add vocal narration or commentary
                to a visual or audio production, such as a video, film,
                commercial, or audiobook. Voiceover helps to guide the audience
                and provide additional information, context, or emotional impact
                to the content being presented.
              </p>
              <p>
                Whether or not you need a voiceover for your project depends on
                your specific goals and objectives. If your project requires an
                explanation of complex information, an added layer of emotional
                impact, or simply a more engaging experience for your audience,
                a voiceover can be an effective solution.
              </p>
            </div>
          </div>
        </Toggle>

        <Toggle title="How much does a voiceover cost?">
          <div className="question">
            <div className="answer">
              <p>
                A short internal project could start at around $250 to $450,
                while a voice-over for an advertising campaign may cost upwards
                of $1000. The cost of voice-over production depends on several
                factors, including the broadcast medium, campaign duration,
                specific voice talent, recording and editing time, and local
                market rates.
              </p>
              <p>
                Our voice-over artists have varying rates based on their
                experience, ability, type of job, and demand. However, to remain
                competitive, most professionals tend to have a standard rate.
                The cost of voice-over production is usually measured and paid
                per hour in the studio and is known as the Basic Session Fee
                (BSF).
              </p>
              <p>
                To get a better understanding of how Afrovo determines costs,
                feel free to check out our pricing page. We pride ourselves on
                our unique relationships and deep insight into the voice-over
                industry, which allow us to offer competitive prices for
                high-quality voice-over productions.
              </p>
              <p>
                With our dedicated project management team, you can rest assured
                that your voice-over project will be smooth sailing from start
                to finish. Contact us today to find out how we can help bring
                your vision to life.
              </p>
            </div>
          </div>
        </Toggle>

        <Toggle title="How do I hire a voice artist on Afrovo?">
          <div className="question">
            <div className="answer">
              <p>
                To hire a voiceover artist on our website, simply place an order
                or request a quote. Our team will then use the provided sample
                script to cast from our pool of talented voiceover artists and
                provide you with auditions to choose from. Our goal is to make
                the voiceover hiring process as seamless and hassle-free as
                possible for you.
              </p>
            </div>
          </div>
        </Toggle>

        <Toggle title="Is Afrovo safe?">
          <div className="question">
            <div className="answer">
              <p>
                Yes, Afrovo is safe to use. We take the security and protection
                of your personal and financial information seriously. Our site
                uses industry-standard SSL encryption to ensure the safe
                transmission of sensitive data, and we regularly monitor our
                systems to detect and prevent any potential security breaches.
                Rest assured that your information is in good hands when you use
                our site.
              </p>
            </div>
          </div>
        </Toggle>
      </AnimateSharedLayout>
    </StyledFaq>
  );
};

export default Faq;

const StyledFaq = styled(motion.div)`
  h3 {
    text-align: center;
    font-weight: 700;
    margin-top: 4.7rem;
  }
  .question {
    /* transition: 0.3s ease-in; */
    .answer {
      padding: 2.4rem 0;

      p {
        margin-bottom: 1.6rem;
      }
      ul {
        margin-bottom: 1.6rem;
      }
      li {
        list-style: none;
        padding-left: 2.4rem;
        font-size: inherit;
      }
    }
  }
  .faq-line {
    background: #cccccc;
    height: 0.1rem;
    margin: 2.4rem 0;
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
  }
`;
