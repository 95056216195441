// Import Packages / Dependencies

// Import Components
import ButtonPrimary, { ButtonPrimarySecondaryBlue } from "./Buttons";

//Import Images
import quickCasting from "../img/quick-casting.png";
import streamlinedService from "../img/streamlined.png";
import managedRemotely from "../img/managed.png";
import budgetFriendly from "../img/budget-friendly.png";

const WhyUs = () => {
  return (
    <div className="why-choose-afrovo space-big">
      <div className="container">
        <h3 className="subheading space-bigger-mid-bottom">
          Why Choose Afrovo?
        </h3>
      </div>
      <div className="why-frovo-wrapper">
        <div className="why-us container">
          <div className="why-img">
            <img src={quickCasting} alt="Quick Casting" />
          </div>
          <div className="why-content">
            <h3 className="subheading-2 ">
              Quick casting, get demos delivered within the hour
            </h3>
            <p>
              Shortlist pre-approved voice demos and get a quote with just a few
              clicks. Getting started is quick and easy.
            </p>
            <a href="/place-order">
              <ButtonPrimarySecondaryBlue>
                Get a Quote <i className="fa-solid fa-arrow-right"></i>
              </ButtonPrimarySecondaryBlue>
            </a>
          </div>
        </div>
        <div className="why-us container reverse">
          <div className="why-content">
            <h3 className="subheading-2">Streamline your schedule</h3>
            <p>
              Short on time for casting and recruiting talent? Leave it to us.
              Leave the casting and freelancer coordination to us so you can
              focus on what you do best.
            </p>
            <a href="/place-order">
              <ButtonPrimarySecondaryBlue>
                Tell us about your project{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </ButtonPrimarySecondaryBlue>
            </a>
          </div>
          <div className="why-img">
            <img src={streamlinedService} alt="Streamlined service" />
          </div>
        </div>
        <div className="why-us container">
          <div className="why-img">
            <img src={managedRemotely} alt="Managed Remotely" />
          </div>
          <div className="why-content">
            <h3 className="subheading-2">Managed Remotely</h3>
            <p>
              Thanks to our cloud-based infrastructure and expert engineers,
              every project can be managed remotely from A to Z, with a single
              point of contact to ensure smooth communication.
            </p>
            <a href="/about">
              <ButtonPrimarySecondaryBlue>
                Learn more about how it works{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </ButtonPrimarySecondaryBlue>
            </a>
          </div>
        </div>
        <div className="why-us container reverse">
          <div className="why-content">
            <h3 className="subheading-2">Budget Friendly</h3>
            <p>
              Get a free, no-pressure consultation and itemized quote. Consult
              with us for free and receive a detailed quote for any project, no
              matter the size.
            </p>
            <a href="/pricing">
              <ButtonPrimarySecondaryBlue>
                See our Pricing
              </ButtonPrimarySecondaryBlue>
            </a>
          </div>
          <div className="why-img">
            <img src={budgetFriendly} alt="Budget Friendly" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
