import styled from "styled-components";
import Footer from "../components/Footer";
import thankYouImg from "../img/thank-you.jpg";

const Thankyou = () => {
  return (
    <StyledThankYou className="thank-you">
      <div className="content">
        <div className="note-wrapper">
          <div className="thank-you-note container space-big">
            <h2>Thank you for getting in touch </h2>
            <img src={thankYouImg} className="space-bigger-mid" alt="" />

            <p>
              We're here to help and ready to get started on your project as
              soon as possible. Our dedicated advisor will be in touch with you
              within the next hour to answer any questions and help get things
              rolling. If you're short on time, don't hesitate to
              <span> give us a call at +254757452055</span> and we'll do our
              best to assist you immediately.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </StyledThankYou>
  );
};

export default Thankyou;

const StyledThankYou = styled.div`
  overflow: auto;
  .thank-you-note {
    background: var(--afrovo-white);
    margin-top: 6.4rem;
    // padding-left: 3.2rem;
    // padding-right: 3.2rem;
    text-align: center;
    width: 50%;

    h2 {
      font-size: 3.2rem;
      color: var(--afrovo-blue);
    }
    p {
      width: 70%;
      margin: auto;
      span {
        color: var(--afrovo-blue);
        font-weight: 600;
      }
    }
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    .content {
      .note-wrapper {
        .thank-you-note {
          width: 92%;
          margin: auto;
          margin-top: 24%;
          text-align: left;
          box-shadow: none;
          h2 {
            width: 92%;
            padding-bottom: 2.4rem;
            margin: auto;
          }
          h2::before {
            content: "";
            margin-top: 3rem;
          }
          p {
            width: 90%;
          }
          img {
            display: none;
            text-align: center;
            padding-left: 10%;
          }
        }
      }
    }
  }
`;
