import { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const Toggle = ({ children, title }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <StyledToggle layout onClick={() => setToggle(!toggle)}>
      <div className="qn">
        <motion.h4 layout>{title}</motion.h4>
        <motion.span className="span" layout>
          <i class="fa-regular fa-plus"></i>
        </motion.span>
      </div>

      {toggle ? children : ""}
      <div className="faq-line">&nbsp;</div>
    </StyledToggle>
  );
};

export default Toggle;

const StyledToggle = styled(motion.div)`
  .qn {
    display: flex;
    justify-content: space-between;
    transition: all 0.2s ease-in;
    h4 {
    }
    .span {
      transform: rotate(-270deg);
      margin-right: 2.4rem;
    }
    &:hover {
      color: var(--afrovo-blue);
    }
  }
`;
