import React from "react";

//Import Images
import niveaLogo from "../img/nivea-logo.png";
import safaricomLogo from "../img/safaricom-logo.png";
import stimaSaccoLogo from "../img/stima-logo.png";
import googleLogo from "../img/google-logo.png";
import airtelLogo from "../img/airtel.png";
import hopeLogo from "../img/hope-international-logo.png";
import saraJ from "../img/sara-j.png";
import markT from "../img/mark-t.png";
import rachelK from "../img/rachel-k.png";
import johnD from "../img/john-d.png";

const Testimonials = () => {
  return (
    <div className="testimonials space-big">
      <div className="testimonials-wrapper container">
        <h4 className="space-mid">
          Trusted by producers, creative directors, and teams globally.
        </h4>
        <div className="logos">
          <img src={niveaLogo} alt="Nivea Logo" />
          <img src={safaricomLogo} alt="Safaricom Logo" />
          <img src={stimaSaccoLogo} alt="stima sacco" />
          <img src={googleLogo} alt="google testimonial logo" />
          <img src={airtelLogo} alt="airtel client logo" />
          <img src={hopeLogo} alt="hope international logo" />
        </div>
        <div className="testimonial-cards">
          <div className="testimonial-card space-mid-hrvr">
            <p>
              “Afrovo exceeded my expectations! The process of finding the
              perfect voiceover artist was so easy, and the final product was
              top-notch. I will definitely use this service again in the
              future.” - Sarah J.
            </p>
            <img src={saraJ} alt="sarah testimonial" />
          </div>
          <div className="testimonial-card space-mid-hrvr">
            <p>
              “"I am extremely impressed with the talent and professionalism of
              the voiceover artists at African Voices. They exceeded my
              expectations and brought my project to the next level. 5 stars all
              the way!"” - Mark T.
            </p>
            <img src={markT} alt="" />
          </div>
          <div className="testimonial-card space-mid-hrvr">
            <p>
              “I was skeptical about using an online service for my voiceover
              needs, but Afrovo exceeded all of my expectations. The account
              managers were responsive and easy to work with. I highly recommend
              this service!” - Rachel K.
            </p>
            <img src={rachelK} alt="" />
          </div>
          <div className="testimonial-card space-mid-hrvr">
            <p>
              “I have used Afrovo for multiple projects now, and every time they
              have exceeded my expectations. The talent pool is diverse and the
              process is seamless. I can't recommend this service enough!’’ -
              John D.
            </p>
            <img src={johnD} alt="John testimonial image" />
          </div>
        </div>
      </div>

      <svg className="svg-testimonial">
        <clipPath id="clip-path-testimonials" clipPathUnits="objectBoundingBox">
          <path d="M0,0.194 c0.367,-0.035,0.458,0.179,0.999,-0.002 c0,0,0,0.994,0,0.994 q-0.5,-0.042,-1,0.004"></path>
        </clipPath>
      </svg>
    </div>
  );
};

export default Testimonials;
