import styled from "styled-components";

const GotQuestions = () => {
  return (
    <StyledGotQuestions className="got-questions space-big">
      <div className="got-wrapper container">
        <div className="got-questions-content">
          <h3 className="subheading space-mid">Got Questions?</h3>
          <p>
            Our primary goal is to provide fast delivery of outstanding
            recordings at an affordable cost. We offer both on-location and
            remote recording options, ensuring that we always have your back and
            that your voice over project is done on time with the best quality
          </p>
        </div>
        <div className="got-questions-contacts">
          <h4>Contact us on</h4>
          <ul>
            <li>
              <i className="fa-solid fa-door-open"></i>
              Open Now
            </li>
            <li>
              <i className="fa-solid fa-phone"></i>+254757452055
            </li>
            <li>
              <i className="fa-solid fa-envelope"></i>sales@afrovo.com
            </li>
          </ul>
        </div>
      </div>
    </StyledGotQuestions>
  );
};

export default GotQuestions;

const StyledGotQuestions = styled.div`
  .got-wrapper {
    display: flex;
    align-items: center;
    .got-questions-content {
      flex: 8;
      // margin: 0 0 0 2.4rem;
      margin-right: 4.7rem;
    }
    .got-questions-contacts {
      flex: 4;
      padding: 2.4rem;
      box-shadow: 0 0.6rem 1rem 0.1rem rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: 0 0.6rem 1rem 0.1rem rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 0.6rem 1rem 0.1rem rgba(0, 0, 0, 0.15);
      ul {
        list-style: none;
        li {
          margin: 1.6rem 0;
          i {
            margin-right: 1.6rem;
          }
        }
      }
    }
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    .got-wrapper {
      flex-direction: column;
      align-items: center;
    }

    .got-questions-content {
      margin-bottom: 4.7rem;
      flex: 1;
    }
    .got-questions-contacts {
      flex: 1;
      width: 92%;
      box-shadow: none !important;
    }
  }
`;
