import { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ReactGA from "react-ga4";

const Navigation = (props) => {
  const bgRef = useRef();

  const showNavBg = () => {
    bgRef.current.classList.toggle("responsive_navigation");
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    let pageView;
    if (pathname === "*") pageView = "not-found";
    else pageView = pathname;
    // ReactGA.pageview(pageView);
    ReactGA.send({ hitType: "pageview", page: "pathname" });
  }, []);

  // const pathname = props.match.path;
  // let pageView;
  // if (pathname === "*") pageView = "not-found";
  // else pageView = pathname;
  // ReactGA.pageview(pageView);

  return (
    <StyledNav>
      <div className="nav-wrapper">
        <div className="navigation">
          <div className="navigation_brand">
            <Link to="/">
              <img src="/afrovo-logo-x1.png" alt="Afrovo Logo" />
            </Link>
          </div>

          <input
            type="checkbox"
            className="navigation_checkbox"
            id="navi-toggle"
          />
          <label htmlFor="navi-toggle" className="navigation_button">
            <span className="navigation_icon">&nbsp;</span>
          </label>

          <div className="navigation_background">&nbsp;</div>
          <nav className="navigation_nav">
            <ul className="navigation_list">
              <li className="navigation_item">
                <Link onClick={showNavBg} to="/" className="navigation_link">
                  Home
                </Link>
              </li>
              <li className="navigation_item">
                <Link
                  onClick={showNavBg}
                  to="/about"
                  className="navigation_link"
                >
                  How It Works
                </Link>
              </li>
              <li className="navigation_item">
                <Link
                  onClick={showNavBg}
                  to="/pricing"
                  className="navigation_link"
                >
                  Pricing
                </Link>
              </li>
              <li className="navigation_item">
                <Link
                  onClick={showNavBg}
                  to="/contact"
                  className="navigation_link"
                >
                  Contacts
                </Link>
              </li>
              <li className="navigation-item desk">
                <Link
                  onClick={showNavBg}
                  to="/place-order"
                  className="place-order-link"
                >
                  <button className="place-order-btn">Place Order</button>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </StyledNav>
  );
};

export default Navigation;

const StyledNav = styled.div`
  //Desktop

  .nav-wrapper {
    background: #219ebc;
    margin: auto;
    position: fixed;
    top: 0;
    z-index: 6990;
    width: 100%;
  }
  .place-order-btn:hover {
    cursor: pointer;
    opacity: 0.8;
    transform: translateY(-2px);
    transition: all 0.2s;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }
  .place-order-btn:active {
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }
  .navigation {
    width: 80.5%;
    background: transparent;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_checkbox {
      display: none;
      width: 0;
    }
    &_button {
    }
    &_icon {
    }
    &_background {
    }
    &_nav {
    }
    &_list {
      display: flex;
      align-items: center;
      list-style: none;
    }
    &_item {
      padding: 0 2.4rem;
      font-size: 1.4rem;
    }
    &_link {
      text-decoration: none;
      color: #fff;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: #fa8f04;
      }
    }
  }
  .responsive_navigation {
    transform: scale(0);
    opacity: 0;
    transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    .responsive_navigation {
      width: 0;
      opacity: 0;
      transition: all;
    }
    .desk {
      display: none;
      width: 0;
    }
    .navigation {
      &_brand {
        display: none;
        width: 0;
      }
      &_checkbox {
        display: none;
      }
      &_button {
        background-color: #fff;
        height: 5rem;
        width: 5rem;
        position: fixed;
        top: 4.5rem;
        right: 4rem;
        border-radius: 50%;
        z-index: 7000;
        box-shadow: 0 1rem 3rem rgba(6, 6, 6, 0.1);
        text-align: center;
        cursor: pointer;
      }
      &_background {
        height: 4rem;
        width: 4rem;
        border-radius: 50%;
        position: fixed;
        top: 4.5rem;
        right: 4.5rem;
        background-image: radial-gradient(#57d5f5, #219ebc);
        z-index: 6000;
        transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);

        /* transform: scale(80); */
      }
      &_nav {
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 6500;

        opacity: 0;
        width: 0;
        transition: all 0.8s;
      }
      &_list {
        display: block;
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        list-style: none;
        text-align: center;
        width: 100%;
      }
      &_item {
        margin: 1.6rem;
      }
      &_link {
        &:link,
        &:visited {
          display: inline-block;
          font-size: 3.2rem;
          font-weight: 300;
          padding: 1rem 2rem;
          text-decoration: none;
          color: #fff;
          text-transform: uppercase;
          background-image: linear-gradient(
            120deg,
            transparent 0%,
            transparent 50%,
            #fff 50%
          );
          background-size: 235%;
          transition: all 0.4s;
        }
        &:hover,
        &:active {
          background-position: 100%;
          color: #219ebc;
          transform: translateX(1rem);
        }
      }

      // FUNCTIONALITY
      &_checkbox:checked ~ .navigation_background {
        transform: scale(80);
      }
      &_checkbox:checked ~ .navigation_nav {
        opacity: 1;
        width: 100%;
      }
      //ICON
      &_icon {
        position: relative;
        margin-top: 2.5rem;
        &,
        &::before,
        &::after {
          width: 2.5rem;
          height: 2px;
          background-color: #023047;
          display: inline-block;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 0;
          transition: all 0.2s;
        }
        &::before {
          top: -1rem;
        }
        &::after {
          top: 1rem;
        }
      }

      &_button:hover .navigation_icon::before {
        top: -1.2rem;
      }
      &_button:hover .navigation_icon::after {
        top: 1.2rem;
      }

      &_checkbox:checked + .navigation_button .navigation_icon {
        background-color: transparent;
      }
      &_checkbox:checked + .navigation_button .navigation_icon::before {
        top: 0;
        transform: rotate(135deg);
      }
      &_checkbox:checked + .navigation_button .navigation_icon::after {
        top: 0;
        transform: rotate(-135deg);
      }
    }
  }
`;
