//import Images
import achieve from "../img/achieve.png";
import whatYouNeed from "../img/what-you-need.png";
import topnotch from "../img/topnotch.png";

import ButtonPrimary, { ButtonPrimarySecondaryBlue } from "./Buttons";

const HiwInfographic = () => {
  return (
    <div className="howItWorksInfographic space-big">
      <div className="hiwInfographic-wrapper container">
        <h3 className="subheading space-bigger-mid-bottom">
          Get started in minutes
        </h3>
        <div className="hiw-cards">
          <div className="hiw-card space-bigger-mid-top">
            <img src={whatYouNeed} alt="" />
            <h4 className="hiw-card-title space-bigger-mid">
              Tell us what you need
            </h4>
            <p>
              Tell us what you need and our team will work to match you with the
              perfect voiceover artist. Let us know your specific requirements
              and we'll handle the rest, ensuring that you get the perfect voice
              to bring your project to life.
            </p>
            <h5>&lt; 5 Minutes</h5>
          </div>
          <div className="hiw-card space-bigger-mid-top">
            <img src={topnotch} alt="" />
            <h4 className="hiw-card-title space-bigger-mid">
              Work with top-notch talent
            </h4>
            <p>
              Our team works with you to iron out the details and schedule
              recording sessions anywhere. Our expert audio engineers are
              passionate about audio quality and ensure that your voiceover is
              perfect for your production.
            </p>
            <h5>&lt; 45 Minutes</h5>
          </div>
          <div className="hiw-card space-bigger-mid-top">
            <img src={achieve} alt="" />
            <h4 className="hiw-card-title space-bigger-mid-top">
              Achieve your goals
            </h4>
            <p>
              Receive top quality voiceovers on time. With Afrovo, you can trust
              that your voiceover needs will be met. Our team of experienced
              voice actors and audio engineers will exceedyour expectations and
              make your project shine.
            </p>
            <h5>Your deadline</h5>
          </div>
        </div>
        <div className="infographic-ctas space-bigger-mid">
          <a href="/pricing">
            <ButtonPrimary>Voiceover rates</ButtonPrimary>
          </a>
          <ButtonPrimarySecondaryBlue>
            Get Started <i className="fa-solid fa-arrow-right"></i>
          </ButtonPrimarySecondaryBlue>
        </div>
      </div>
    </div>
  );
};

export default HiwInfographic;
