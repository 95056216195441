//Import Styles
import "./App.css";

//Import Packages
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";

import ReactGA from "react-ga4";

//Import  Components
import Home from "../src/screens/Home";
import HowItWorks from "./screens/HowItWorks";
import Pricing from "./screens/Pricing";
import Contact from "./screens/Contact";
import PlaceOrder from "./screens/PlaceOrder";
import Thankyou from "./screens/Thankyou";
import Navigation from "./components/Navigation";
//Animation

ReactGA.initialize("G-9MKX9GSQFE");

const App = (props) => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<HowItWorks />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/place-order" element={<PlaceOrder />}></Route>
        <Route path="/thank-you" element={<Thankyou />}></Route>
      </Route>
    )
  );

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;

const Root = () => {
  return (
    <>
      <div>
        <Navigation />
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};
