import ButtonPrimary from "../components/Buttons";
import Footer from "../components/Footer";
import GotQuestions from "../components/GotQuestions";
import HeroData from "../components/HeroData";

//Image Import
import contactPgImg from "../img/contactpg-img.png";

const Contact = () => {
  return (
    <div className="content">
      <div className="contact">
        <main>
          <div className="contact-hero">
            <div className="Hero space-big">
              <div className="hero-wrapper container">
                <h1 className="space-big-bottom">
                  Love to hear from you,
                  <br /> Get in touch
                </h1>
              </div>
            </div>
          </div>

          <div className="contact-wrapper space-big container">
            <div className="contact-graphic space-big-bottom">
              <img src={contactPgImg} alt="" />
              <p>
                Want to know if Afrovo is the best solution for you? Or do you
                need to discuss a project already in production? Get in touch by
                phone or Email.
              </p>
            </div>
            <div className="content">
              <div className="content-text">
                <h3 className="subheading">Opening Hours</h3>
                <p className="space-bigger-mid">
                  We are open from 8:30am to 11:00pm GMT+3, Monday to Saturday.
                  If you call outside these hours, please leave a message and
                  we'll respond the next working day. If you enquire within
                  operating hours, in most cases, we'll respond in less than 1
                  hour. Our primary goal is to provide fast delivery of
                  outstanding recordings at an affordable cost. We offer both
                  on-location and remote recording options, ensuring that we
                  always have your back and that your voice over project is done
                  on time with the best quality
                </p>
                <h3 className="subheading">New Customers</h3>
                <p className="space-bigger-mid">
                  Start your journey by telling us about your project here.
                </p>
                <div className="contact-cta">
                  <a href="/place-order">
                    <ButtonPrimary>Start your journey here</ButtonPrimary>
                  </a>
                </div>
              </div>
              <div className="contact-card">
                {" "}
                <div className="contactpg-contact-card">
                  <h4>Contact us on</h4>
                  <ul>
                    <li>
                      <i className="fa-solid fa-door-open"></i>
                      Open Now
                    </li>
                    <li>
                      <i className="fa-solid fa-phone"></i>+254757452055
                    </li>
                    <li>
                      <i className="fa-solid fa-envelope"></i>sales@afrovo.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <svg className="svg-heroes">
            <clipPath id="clip-path-heroes" clipPathUnits="objectBoundingBox">
              <path d="M1,0 V1 a4,10,0,0,0,-1,0 V0"></path>
            </clipPath>
          </svg>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
