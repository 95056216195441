// Import Images

//IMPORT COMPONENTS
import HomeHero from "../components/HomeHero";
import Footer from "../components//Footer";
import ServiceTypes from "../components/ServiceTypes";
import WhyUs from "../components/WhyUs";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";

// Animations
import { motion } from "framer-motion";

const Home = () => {
  return (
    <motion.div>
      <div className="content">
        <main>
          <HomeHero />
          <ServiceTypes />
          <WhyUs />
          <Testimonials />
          <Faq />
        </main>
      </div>
      <Footer />
    </motion.div>
  );
};

export default Home;
