import styled from "styled-components";

const ButtonPrimary = styled.button`
  background: var(--afrovo-orange-light);
  color: var(--afrovo-white);
  font-size: 1em;
  padding: 1.6rem 4.8rem;
  border-radius: 3rem;
  border: 0.15rem solid var(--afrovo-orange-light);
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }
`;
export default ButtonPrimary;

export const ButtonPrimarySecondary = styled(ButtonPrimary)`
  background: transparent;
  color: var(--afrovo-orange);
  border: 0.15rem solid var(--afrovo-orange);
`;

export const ButtonPrimarySecondaryBlue = styled(ButtonPrimary)`
  background: transparent;
  color: var(--afrovo-blue);
  border: 0.18rem solid var(--afrovo-blue);
`;

export const ButtonPrimarySecondaryWhite = styled(ButtonPrimary)`
  background: transparent;
  color: var(--afrovo-white);
  border: 0.15rem solid var(--afrovo-white);
`;

export const ButtonPrimaryBlue = styled(ButtonPrimary)`
  background: var(--afrovo-blue-dark);
  color: var(--afrovo-white);
  border: 0.15rem solid var(--afrovo-blue-dark);
`;
